.logo
    lost-column 7/24
    +below('l')
        lost-column 1/2
    +below('s')
        lost-column 1/2
    +below('xs')
        lost-column 1/2
    a
        color inherit
        display block

    img.logo-screen
        max-width 100%
        position: relative
        z-index 1
        margin-bottom -2.8em
        +below('s')
            display none

    img.logo-mobile
        display none
        +below('s')
            display block

.header .fastcontact
    lost-column 5/24
    +below('l')
        display none

header
    margin-top 15px
    .wrapper
        z-index 7

.fastcontact
    font-size 1.12rem
    text-align center
    margin-top 2.1em
    a, a:visited, a:link, a:hover
        color inherit
    .phone-number
        font-size 2rem
        line-height 1.9rem
        .blue
            color primary-color