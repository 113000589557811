.lead
    min-height 530px
    height 50vh
    background secondary-color
    margin-bottom 2em
    +below('s')
        min-height 150px
        height 10vh
    .wrapper
        +below('s')
            background url('slider/1.jpg') no-repeat center 90%/cover
    img
        min-height 530px
        height 50vh
        +below('s')
            display none

    .wrapper
        height 100%

    &--small
        height 10vh
        background-position center 35%
        min-height 140px
        .wrapper
            background none
            .image
                background url('slider/1.jpg') no-repeat center 90%/cover
                opacity 0.25
                height 100%
            .display-1
                text-transform uppercase
                color #4991cc
                font-size 2rem
                text-align center
                margin-top 1.5em;
                +below('s')
                    margin-top 0.85em
                position absolute
                top 0
                width 100%
                font-weight 800

        +below('s')
            min-height 100px
            height 5vh
