.main
    lost-utility clearfix
    color #444
    min-height 400px
    min-height 40vh
    &.startseite
        min-height auto

.content
    lost-column 1/1
    font-size 1rem
    line-height 1.7
    margin-top .5em

    p
        margin 0 0 1em

    img
        max-width 100%;

    .left-content
        lost-column 2/5
        +below('s')
            lost-column 1/1

    .right-content
        lost-column 3/5
        +below('s')
            lost-column 1/1