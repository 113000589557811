.contact-left
  lost-column 6/12
  lost-offset 1/12
  +below('s')
    lost-offset 0/12 row 0
    lost-column 1/1


.contact-right
  lost-column 5/12
  +below('s')
    lost-column 1/1
    margin-top 2em

  font-weight 600
  .time
    float right
    font-weight 300
  iframe
    height 290px
    padding-bottom 2em