.nav-main
    lost-column 6/12
    +below('l')
        lost-column 1/2
        text-align right
        padding-top 2em
    +below('s')
        lost-column 1/2
    +below('xs')
        lost-column 1/2

    .nav-items
        +below('l')
            display none

.nav-items
    margin 0
    padding 0
    text-align center
    margin-top 2.0em
    font-size 0.9rem

.nav-main .nav-item
    display inline-block
    padding 0
    margin 0
    position relative
    &:first-child
        margin-left 0
    .nav-link:after
        content "\A"
        width 4px
        height 4px
        border-radius 50%
        background primary-color
        display inline-block
        vertical-align middle
        margin 0 0 4px 0.8em
    &:last-child .nav-link:after
        content none

    +below('m')
        margin 0 1em

    &--active > .nav-link,
    &--child-active > .nav-link
        color primary-color

    &:hover .nav-sub
        display block


.nav-link
    display block
    color inherit
    font-weight 300
    padding 1.1em .8em 1.1em 0
    text-transform uppercase

.nav-main .nav-sub
    position absolute
    display none
    left 0
    top 51px
    min-width 250px
    box-shadow 0 2px 6px rgba(0, 0, 0, .1)
    .nav-item
        display block
        margin 0

    .nav-link
        display block
        margin 0
        padding .8em
