.lead
  position relative
  .carousel-items
    margin-top 0 !important

.bx-wrapper .bx-viewport
  border: none !important
  left: 0px !important
  box-shadow none !important

.carousel-items
  left 0px !important
  padding-left 0px !important
