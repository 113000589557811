html
    +below('s')
        font-size 90%

body
    font-family $font-family-sans-serif;
    font-weight 300
    font-size 16px
    color #2f2f2f
    line-height 1.6
    // lost-utility edit
    border-top: 6px solid #9db9e2;

a
    color primary-color
    text-decoration none

hr
    border none
    border-bottom 1px solid #ddd

.backbone
    background url('bg-img.png') no-repeat 100% 45%
    background-size: auto 100%;
    position: fixed
    width 10%
    height 100%
    opacity 0.5
    z-index 6
    right 0
    +below('xl')
        z-index 0
    +below('l')
        background none