.footer-wrapper
    border-bottom 6px solid #9db9e2
    padding-bottom 20px

.footer
    margin-top 3.1em
    lost-utility clearfix
    background third-color
    color #2f2f2f
    lost-column 1/1
    font-size 0.9rem
    a
        color #2f2f2f
    .logo-stamp
        lost-column 4/24
        position relative
        text-align center
        +below('l')
            lost-column 2/14 3
        +below('m')
            lost-column 1/1
        img
            padding 3em 0 2.2em 1em
    .address
        lost-column 7/24
        padding 2em 0 2em 0em
        +.address-contact
            padding-top 4.5em
            +below('m')
                padding 0
        +below('l')
            lost-column 4/14 3
        +below('m')
            lost-column 1/1
            text-align center
            padding 1em 0em 0em 0em
            
    .address-contact
        lost-column 7/24
        +below('l')
            lost-column 4/14 3
        +below('m')
            lost-column 1/1
            text-align center


    .footer-contact
        lost-column 6/24
        padding-top 2.5em
        +below('m')
            lost-column 1/1
            text-align center
            padding 0
        .fastcontact
            lost-column 1/1
            text-align left
            +below('m')
                text-align center
                padding 1em 0 2em
                margin 0