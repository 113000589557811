.behandlungen-link
  lost-column 4/12 3
  position relative
  margin-bottom 3em
  +below('l')
    lost-column 1/2 2
  +below('s')
    lost-column 1/1 1
  img
    padding 1.5em 0 0 1.5em
    z-index 3
    position relative
    max-width 100%;
  .blue-block
    background secondary-color
    position absolute
    height 100%
    width 70%
    +below('l')
      width 95%
    +below('s')
      width 75%
    +below('xs')
      width 95%
    z-index 1
  span
    margin 0em 0 0.4em 1.5em
    position relative
    display: block
    text-transform uppercase
    color #4991cc
    font-weight 600
    font-size 0.9rem
    z-index 2

.behandlungen-detail
    .behandlungen-link
      lost-column 1/1
    .behandlungen-image
      lost-column 1/3
      padding-bottom 2em
      img
        max-width 100%
      +below('s')
        lost-column 1/1
    .behandlungen-description
      lost-column 2/3
      padding-left 2em
      +below('s')
        lost-column 1/1
        padding-left 0
