transitionProperties = .5s cubic-bezier(.16, .68, .43, .99)

.hamburger
    display none
    position relative
    background primary-color;
    font-size 1.5rem
    color #fff
    text-transform uppercase
    font-weight 500
    padding .05em 0.25em
    border-radius 5px;
    text-align right

    +below('l')
        display inline-block


.nav-offscreen
    .nav-items
        text-align left
    .nav-item
        display block

    .nav-link
        font-weight 500
        margin-top .5em

    .nav-sub
        display block
        margin-left 1em
        position static
        .nav-link
            font-weight normal

    .nav-link
        font-size 1rem
        line-height 1.5
        padding 0
        margin-bottom .5em

.offscreen-nav
    width offscreen-nav-width
    height 100%
    background #fff
    position fixed
    left 0
    top 0
    transform translate3d(-100%, 0, 0)
    transition transform transitionProperties
    z-index: 6000
    overflow-y auto
    will-change transform
    padding: 1em 15px

    .nav-item
        margin-left 0

    .nav-item--active .nav-link
        color #333
        font-weight bold

.site-overlay
    background rgba(0,0,0,.5)
    position fixed
    left 0
    top 0
    width 100%
    height 100%
    z-index: 5000
    visibility hidden
    opacity 0
    will-change opacity, visibility
    transition all transitionProperties

.site-wrapper, .nav-push
    transform translate3d(0, 0, 0)
    transition transform transitionProperties
    will-change transform

body.nav-visible
    background #333
    overflow-x hidden

    .header
        opacity 0

    .site-wrapper
        overflow hidden
        background #fff

    .site-wrapper, .nav-push
        transform scale(.9)

    .offscreen-nav
        transform translate3d(0, 0, 0)
        box-shadow 0 0 25px rgba(0,0,0,.2)

    .site-overlay
        visibility visible
        opacity 1
